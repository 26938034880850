interface MessageSubscriber {
    eventType: string,
    callback: (data?: DataMessage) => void
}

interface DataMessage {
    type: string
    widgetId: string
    payload: any
}
export const calcHeight = (node: HTMLElement | HTMLDivElement) => {
    return node.scrollHeight
}

class MessageSubscribers {
    private subscribers = [] as MessageSubscriber[]

    protected notifySubscribers (data: DataMessage) {
        this.subscribers.forEach(s => {
            if (s.eventType !== data.type) return null
            s.callback(data)
        })
    }

    public subscribe (subscriber: MessageSubscriber) {
        this.subscribers.push(subscriber)
        return () => {
            this.unSubscribe(subscriber)
        }
    }

    public unSubscribe (subscriber: MessageSubscriber) {
        const newSubscribers = this.subscribers.filter(s => {
            return !(s.eventType === subscriber.eventType && s.callback === subscriber.callback)
        })
        const isUnsobscribed = this.subscribers.length !== newSubscribers.length
        this.subscribers = newSubscribers
        return isUnsobscribed
    }
}

class MessageService extends MessageSubscribers {
    public $container: HTMLElement | HTMLDivElement = document.body
    private origin = ''

    constructor() {
        super();
        window.addEventListener('message', this.receivedMessage.bind(this))
    }

    public sendMessage (type: string, payload: any) {
        const data = {
            type,
            payload,
        } as DataMessage
        if (!this.origin) {
            console.warn('The widget does not have a source for sending a message')
            return this
        }
        // console.log('---- Widget send msg', data);
        window.top?.postMessage(JSON.stringify(data), this.origin)
        return this
    }

    private receivedMessage (e: MessageEvent<string>) {
        console.log(e.data);
        const jsonData = typeof e.data === 'string' ? e.data : JSON.stringify(e.data)
        try {
            const data = JSON.parse(jsonData) as DataMessage
            // console.log('---- Widget get msg', e);
            if (!jsonData) return false;
            if (!this.origin) {
                if (data.type !== 'WG_LOAD' && data.type !== 'SCROLL_Y') return false
                this.setOrigin(e.origin)
            }
            this.reduceMessage(data)
            this.notifySubscribers(data)
            return true;
        } catch (e) {
            return true;
        }
    }

    public setOrigin (val: string) {
        this.origin = val
        return this
    }

    public setContainer (val: HTMLDivElement) {
        this.$container = val
    }

    private reduceMessage (data: DataMessage) {
        switch(data.type) {
            case 'WG_LOAD': {
                this.sendMessage('SITE_RESIZE', calcHeight(this.$container))
                break
            }
            case 'HEIGHT_RESIZE': {
                document.documentElement.style.setProperty('--vh', `${data.payload}px`);
                break
            }
        }
    }
}

export const messageService = new MessageService()
