import {createSlice, PayloadAction} from '@reduxjs/toolkit'

type mobileDataState = {
  savedPath: string,
  isMobile: boolean | undefined,
}

const initialState: mobileDataState = {
  savedPath: '/',
  isMobile: undefined,
}

const mobileDataSlice = createSlice({
  name: 'mobileData',
  initialState,
  reducers: {
    updateMobileDataPath(state, action: PayloadAction<string>) {
      state.savedPath = action.payload
    },
    updateMobileDataIsMobile(state, action: PayloadAction<boolean>) {
      state.isMobile = action.payload
    }
  }
})

export const {
  updateMobileDataIsMobile,
  updateMobileDataPath,
} = mobileDataSlice.actions
export default mobileDataSlice.reducer