import React, { useEffect } from 'react'
import { useAppDispatch } from './hook'
import { updateArrayCity, updateLogIn } from './store/mainInfoSlice'

import { RequestAxios } from "./api"
import { getCitiesList, getClubsList } from "./api/get"
import { CitiesList } from './api/get/getCitiesList/interfaces'

import { Trainers } from './pages/Trainers/Trainers'
import { Profile } from './pages/Profile/Profile'
import './App.css'
import { Route, Routes } from 'react-router-dom'

import {calcHeight, messageService, useMessageInit, useMutationObserver} from "./handlers/messages";

type ClubsArray = {
  name: string,
  id: number,
  guid: string,
  address: string,
}
type CytiesArray = {
  name: string,
  id: number,
  guid: string,
  clubs: ClubsArray[],
}

function App() {
  const dispatch = useAppDispatch()
  useEffect( () => {
      (async function () {
          await RequestAxios.login()

          const { data: cities } = await getCitiesList()
          //console.log('got cities', cities)

          async function getAsyncClubs(data: CitiesList[]){
            const result: CytiesArray[] = []
            let countId = 1

            for(const item of data) {
              if(item.localityName) {

                const { data: clubs } = await getClubsList(item.localityName)
                const clubsArr: ClubsArray[] = [
                  {
                    name:'Не выбрано',
                    id: 0,
                    guid: '',
                    address: '',
                  }
                ]
                clubs.map((clubsItem) => {
                  if(clubsItem.name) {
                    clubsArr.push({
                      name: clubsItem.name,
                      id: clubsItem.id,
                      guid: clubsItem.storeGuid,
                      address: clubsItem.address,
                    })
                  }              
                })

                result.push({
                  name: item.localityName,
                  id: countId,
                  guid: '',
                  clubs: clubsArr,
                })

                ++countId
              }
            }
            return result     
          }

          const citiesArr: CytiesArray[] = await getAsyncClubs(cities)
          dispatch(updateArrayCity(citiesArr))
          dispatch(updateLogIn(true))
      })()
  },[]);

    const handleMutation = () => {
        messageService.sendMessage('SITE_RESIZE', calcHeight(messageService.$container))
    }
    const { containerRef } = useMutationObserver<HTMLDivElement>(handleMutation)
    useMessageInit(containerRef)

  return (
    <div ref={containerRef}>
        <Routes>
            <Route path='/' element={<Trainers/>}/>
            <Route path='/profile' element={<Profile/>}/>
        </Routes>
    </div>
  );
}

export default App;
