import axios, {AxiosRequestConfig, AxiosResponse} from "axios";
import {API_TOKEN_TYPE, AUTH_TOKEN_TYPE, BASE_API_URL, COOKIE_TOKEN_ACCESS, LOGIN_URL} from "./constants";
import {LoginResp} from "./interfaces";
import qs from 'qs';

export class AuthService {
    public static login = async (username: string, password: string): Promise<AxiosResponse<LoginResp>> => {
        return axios.post<LoginResp>(LOGIN_URL,
            qs.stringify({
                grant_type: 'password',
                username,
                password,
            }),
            {
                headers: {
                    'Authorization': `${AUTH_TOKEN_TYPE} ${process.env.AUTH_BASE_TOKEN}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            },
        )
    }
}

export const authAxios = axios.create({
    withCredentials: false,
    baseURL: BASE_API_URL
})

authAxios.interceptors.request.use((config: AxiosRequestConfig) => {
    const access = localStorage.getItem(COOKIE_TOKEN_ACCESS);
    config.headers = !!config.headers ? config.headers : {};
    config.headers.Authorization = access ? `${API_TOKEN_TYPE} ${access}` : '';
    return config;
})

authAxios.interceptors.response.use(
    (response) => {
        return response
    },
    async (err) => {
        const originalRequest = err.config;
        if (err?.response?.status === 401 && originalRequest && !originalRequest._isRetry) {
            originalRequest._isRetry = true;
            try {
                const resp = await AuthService.login(process.env.AUTH_LOGIN || '', process.env.AUTH_PASSWORD || '');
                localStorage.setItem(COOKIE_TOKEN_ACCESS, resp.data.access_token);
                return authAxios.request(originalRequest);
            } catch (e) {
                console.log('НЕТ АВТОРИЗАЦИИ retry');
            }
        }else if(err?.response?.status === 404 || err?.response?.status === 403){
            return err?.response;
        }
        throw err;
    }
)

export const axiosDefaultHeaders = {
    headers: { 'Content-Type': 'application/vnd.bitafit.store-directions+json;v=1' }
}
