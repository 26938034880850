import { useEffect, useRef } from 'react';

export const useMutationObserver = <Ref extends HTMLElement>(callback: (newHeight?: number) => void) => {

    const containerRef = useRef<Ref>(null)

    useEffect(() => {
        const container = containerRef.current
        if (container) {
            const observer = new MutationObserver(function () {
                callback(container.scrollHeight)
            });

            observer.observe(container, {
                attributes: true,
                childList: true,
                subtree: true
            });

            return () => {
                observer.disconnect()
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return { containerRef }
}