import React, {useCallback} from 'react'
import {useAppSelector} from '../../hook'
import {FilterModule} from '../FilterModule/FilterModule'
import './FilterMain.scss'

interface FilterMainProps {
  openedModule: string
  openCloseModule: Function
    isBlockCityAndClub?: boolean
}

export const FilterMain: React.FC<FilterMainProps> = (props) => {
  const {
    openedModule = '',
    openCloseModule,
      isBlockCityAndClub = false
  } = props

  const choicen = useAppSelector(state => state.choicen.main)
  const citiesArr = useAppSelector(state => state.mainInfo.cities)
  const clubsArr = useAppSelector(state => state.mainInfo.clubs)
  const zonesArr = useAppSelector(state => state.mainInfo.zones)

    const handleOpenCloseModule = useCallback((module: string) => {
        if(!isBlockCityAndClub){
            openCloseModule(module);
        }
    }, [isBlockCityAndClub, openCloseModule]);

  return (
    <div className='Filter'>
      <div className='Filter_item'
        onClick={() => handleOpenCloseModule('citi')} style={{opacity: !isBlockCityAndClub ? 1 : 0.5}}>
          <div className='Filter_item_topic'>Город</div>
          <div className='Filter_item_text'>{choicen.city.name}</div>
          <div className='Filter_item_triangle'/>
          {openedModule === 'citi' ? <FilterModule 
            choicen={choicen.city.name}
            itemArr={citiesArr}
            param={`${isBlockCityAndClub ? 'block=true&': ''}clubId=0&zoneId=0&qualId=0&cityId`}
            /> : false}
      </div>
      <div className='Filter_item'
        onClick={() => handleOpenCloseModule('club')} style={{opacity: !isBlockCityAndClub ? 1 : 0.5}}>
        <div className='Filter_item_topic'>Клуб</div>
        <div className='Filter_item_text'>{choicen.club.name}</div>
        <div className='Filter_item_triangle'/>
          {openedModule === 'club' ? <FilterModule 
            choicen={choicen.club.name}
            itemArr={clubsArr}
            param={`${isBlockCityAndClub ? 'block=true&': ''}zoneId=0&qualId=0&cityId=${choicen.city.id}&clubId`}
            /> : false}
      </div>
      <div className='Filter_item'
        onClick={()=> openCloseModule('zone')}>
        <div className='Filter_item_topic'>Зона</div>
        <div className='Filter_item_text'>{choicen.zone.name}</div>
        <div className='Filter_item_triangle'/>
        {openedModule === 'zone' ? <FilterModule 
            choicen={choicen.zone.name}
            itemArr={zonesArr}
            param={`${isBlockCityAndClub ? 'block=true&': ''}qualId=0&cityId=${choicen.city.id}&clubId=${choicen.club.id}&zoneId`}
            /> : false}
      </div>
      {/* <div className='Filter_item'>
        <div className='Filter_item_topic'>Квалификация</div>
        <div className='Filter_item_text'>{choicen.qual.name}</div>
        <div className='Filter_item_triangle'/>
        {openedModule === 'qual' ? <FilterModule 
            choicen={choicen.qual.name}
            itemArr={qualsArr}
            param={`cityId=${choicen.city.id}&clubId=${choicen.club.id}&zoneId=${choicen.zone.id}&qualId`}
            /> : false}
      </div> */}
    </div>
  )
}
