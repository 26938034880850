import { configureStore } from '@reduxjs/toolkit'
import choicenReducer from './choicenSlice'
import mainInfoReducer from './mainInfoSlice'
import mobileDataReducer from './mobileData'

const store = configureStore({
  reducer: {
    choicen: choicenReducer,
    mainInfo: mainInfoReducer,
    mobileData: mobileDataReducer,
  }
})

export default store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch