import {createSlice, PayloadAction} from '@reduxjs/toolkit'

type CitiesArray = {
  name: string,
  id: number,
  guid: string,
  clubs: ClubsArray[],
}

type ClubsArray = {
  name: string,
  id: number,
  guid: string,
  address: string,
}

type PersonnelArray = {
  id: number,
  firstName: string,
  surname: string,
  hrefMainPhoto: string,
  personnelGuid: string,
  emptyPhoto: boolean,
}

type ZonesArray = {
  id: number,
  name: string,
  guid: string,
}

type ProfileData = {
  surname: string,
  firstName: string,
  patronymic: string,
  qualifications: string,
  achievements: Array<string>,
  education: Array<string>,
  storesAddress: string,
  storesName: string,
  storeId: number,
  personnelGuid: string,
}

type mainInfoState = {
  cities: CitiesArray[],
  clubs: ClubsArray[],
  personnel: PersonnelArray[],
  filteredPers: PersonnelArray[],
  zones: ZonesArray[],
  profile: ProfileData,
  logIn: boolean,
}

const initialState: mainInfoState = {
  
  cities: [
    {    
      name: '',
      id: 0,
      guid: '',
      clubs: [
        {
          name:'',
          id: 0,
          guid: '',
          address: '',
        },
      ], 
    },
  ], 

  clubs: [
    {
      name:'',
      id: 0,
      guid: '',
      address: '',
    },
  ], 

  personnel: [
    {
      id: 0,
      firstName: '',
      surname: '',
      hrefMainPhoto: '',
      personnelGuid: '',
      emptyPhoto: true,
    },
  ],

  filteredPers: [
    {
      id: 0,
      firstName: '',
      surname: '',
      hrefMainPhoto: '',
      personnelGuid: '',
      emptyPhoto: true,
    },
  ],

  zones: [
    {
      id: 0,
      name: '',
      guid: '',
    },
  ],

  profile: {
    surname: '',
    firstName: '',
    patronymic: '',
    qualifications: '',
    achievements: [''],
    education: [''],
    storesAddress: '',
    storesName: '',
    storeId: 0,
    personnelGuid: '',
  },
  logIn: false,
}

const mainInfoSlice = createSlice({
  name: 'mainInfo',
  initialState,
  reducers: {
    updateArrayCity(state, action: PayloadAction<CitiesArray[]>) {
      state.cities = action.payload
    },
    updateArrayClub(state, action: PayloadAction<ClubsArray[]>) {
      state.clubs = action.payload
    },
    updateArrayPersonnel(state, action: PayloadAction<PersonnelArray[]>) {
      state.personnel = action.payload
    },
    updateArrayFilteredPers(state, action: PayloadAction<PersonnelArray[]>) {
      state.filteredPers = action.payload
    },
    updateArrayZones(state, action: PayloadAction<ZonesArray[]>) {
      state.zones = action.payload
    },
    clearArrayPersonnel(state) {
      state.personnel = [
        {
          id: 0,
          firstName: '',
          surname: '',
          hrefMainPhoto: '',
          personnelGuid: '',
          emptyPhoto: true,
        },
      ]
    },
    clearArrayFilteredPers(state) {
      state.filteredPers = [
        {
          id: 0,
          firstName: '',
          surname: '',
          hrefMainPhoto: '',
          personnelGuid: '',
          emptyPhoto: true,
        },
      ]
    },
    clearArrayZones(state) {
      state.zones = [
        {
          id: 0,
          name: '',
          guid: '',
        },
      ]
    },
    updateProfileData(state, action: PayloadAction<ProfileData>) {
      state.profile = action.payload
    },
    updateLogIn(state, action: PayloadAction<boolean>) {
      state.logIn = action.payload
    },
  }
})

export const {
  updateArrayCity,
  updateArrayClub, 
  updateArrayPersonnel,
  updateArrayFilteredPers,
  updateArrayZones,
  clearArrayPersonnel,
  clearArrayFilteredPers,
  clearArrayZones,
  updateProfileData,
  updateLogIn,
} = mainInfoSlice.actions
export default mainInfoSlice.reducer

