import React from 'react'
import { Link } from "react-router-dom";
import './FilterModule.scss'

type propItemArray = {
  name: string,
  id: number,
  guid: string,
}

interface FilterModuleProps {
  choicen: string;
  itemArr: propItemArray[];
  param: string;
}

export const FilterModule: React.FC<FilterModuleProps> = (props) => {
  const {
    choicen,
    itemArr,
    param,
  } = props

  return (
    <>  
      {itemArr.length > 1 ?
        <div className='Filter-module'>
          <div className='Filter-module_list'>
            {itemArr.map((item, index) => {
              return (
                <Link key={index} to={`/?${param}=${item.id}`}>
                  <div                  
                    className={`Filter-module_list_item ${item.name === choicen? 'choicen' : ''}`}
                  >{item.name}</div>
                </Link>
              )
            })} 
          </div>
        </div>: false //<div className='Filter-module_list_item'>Выберите город</div>
      } 
    </>
  )
}
