import {authAxios, AuthService, axiosDefaultHeaders} from "./axios";
import {COOKIE_TOKEN_ACCESS} from "./constants";

export const RequestAxios = {
    get<T>(url: string, config?: {}){
        return authAxios.get<T>(url, {...axiosDefaultHeaders, ...config});
    },
    post<T>(url: string, data?: any){
        return authAxios.post<T>(url, data, axiosDefaultHeaders);
    },
    async login(){
        try {
            const resp = await AuthService.login(process.env.AUTH_LOGIN || '', process.env.AUTH_PASSWORD || '')
            localStorage.setItem(COOKIE_TOKEN_ACCESS, resp.data.access_token)
            return resp;
        } catch (e: any) {
            console.log(`Login error: ${e?.response?.data?.message}`);
        }
    }
}