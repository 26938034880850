import { messageService, calcHeight } from './messages'
import { useEffect } from 'react'

export const useMessageInit = (containerRef: React.RefObject<HTMLDivElement>) => {
    useEffect(() => {
        // set iframe-container as app container and call SITE_RESIZE after iframe load
        const appContainer = containerRef.current
        const resizeMessage = () => messageService.sendMessage('SITE_RESIZE', calcHeight(messageService.$container))
        window.addEventListener('resize', resizeMessage)
        if (appContainer) messageService.setContainer(appContainer)
        const unsobscribe = messageService.subscribe({
            eventType: 'WG_LOAD',
            callback: () => resizeMessage
        })

        const changeShow = (data : any) => {
            if(data.payload !== false){
                document.documentElement.style.setProperty('--scr', `${data.payload}px`);
            }
            console.log(data);
        }

        const unsubscribe = messageService.subscribe({
            eventType: 'SCROLL_Y',
            callback: (data) => changeShow(data)
        })

        return () => {
            unsobscribe()
            unsubscribe();
            window.removeEventListener('resize', resizeMessage)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
}