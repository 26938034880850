import React, {useEffect, useState}  from 'react'
import {useAppSelector, useAppDispatch} from '../../hook'
import { Link, useParams } from 'react-router-dom'
import {
  updateChoicenMain,
} from '../../store/choicenSlice'
import {
  updateArrayClub,
  updateArrayPersonnel,
  updateArrayFilteredPers,
  updateArrayZones,
  clearArrayZones,
  clearArrayFilteredPers,
} from '../../store/mainInfoSlice'
import { updateMobileDataIsMobile, updateMobileDataPath } from '../../store/mobileData'
import {
  getPersonnelList, 
  getZonesOfClubList,
  getPersonnelOfZoneList,
} from "../../api/get";
import {FilterMain} from '../../components/FilterMain/FilterMain'
import emptyIMG from '../../images/empty.png'
import { Modal } from '../../components/Modal/Modal';
import './Trainers.scss'
import { PhotoCard } from '../../components/PhotoCard/PhotoCard'
import { useQuery } from '../../hooks/useQuery'


type PersonnelArray = {
  id: number,
  firstName: string,
  surname: string,
  hrefMainPhoto: string,
  personnelGuid: string,
  emptyPhoto: boolean,
}

type choicenItem = {
  id: number,
  name: string,
  guid: string,
}

export const Trainers: React.FC = () => {
  const dispatch = useAppDispatch()
  const choicen = useAppSelector(state => state.choicen.main)
  const citiesArr = useAppSelector(state => state.mainInfo.cities)
  const zonesArr = useAppSelector(state => state.mainInfo.zones)
  const personArr = useAppSelector(state => state.mainInfo.personnel)
  const filteredPersonArr = useAppSelector(state => state.mainInfo.filteredPers)

  const isMobile = useAppSelector(state => state.mobileData.isMobile)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [openedModule, setOpenedModule] = useState<string>('')
  
  const params = useQuery()
  //console.log('params = ', params)
  const gotParamCityId = params.get('cityId')
  const gotParamClubId = params.get('clubId')
  const gotParamZoneId = params.get('zoneId')
  const gotParamQualId = params.get('qualId')
  const gotBlockParam = params.get('block');
  const paramCityId = gotParamCityId? +gotParamCityId : 0
  const paramClubId = gotParamClubId? +gotParamClubId : 0
  const paramZoneId = gotParamZoneId? +gotParamZoneId : 0
  const paramQualId = gotParamQualId? +gotParamQualId : 0

  const checkSize = () => window.innerWidth <= 719

  useEffect(() => {
    dispatch(updateMobileDataIsMobile(checkSize()))
  }, [])

  useEffect(() => {
    if(citiesArr.length > 1){
      let isChange = false
      let makeCity: choicenItem = choicen.city
      let makeClub: choicenItem = choicen.club
      let makeZone: choicenItem = choicen.zone
      let makeQual: choicenItem = choicen.qual

      // ****************************** got param CLUB ID only ******************************

      if(!gotParamCityId && gotParamClubId && paramClubId > 0 && paramClubId !== choicen.club.id) {
        const findCity = citiesArr.filter((city) => {
          const findClub = city.clubs.filter(club => club.id == paramClubId)
          if(findClub.length) {
            makeClub = {
              name: findClub[0].name,
              id: findClub[0].id,
              guid: findClub[0].guid,
            }
            return true
          }
        })
        if(findCity.length) {
          makeCity = {
            name: findCity[0].name,
            id: findCity[0].id,
            guid: findCity[0].guid,
          }
          isChange = true
          const findClubs = findCity[0].clubs
          if(findClubs.length) {
            dispatch(updateArrayClub(findClubs))
          }
        }
      }

      // ****************************** got param CITY ******************************

      if(gotParamCityId && paramCityId !== choicen.city.id) {
        const findCity = citiesArr.filter(city => city.id === paramCityId)
        if(findCity.length) {
          makeCity = {
            name: findCity[0].name,
            id: findCity[0].id,
            guid: findCity[0].guid,
          }
          isChange = true
          const findClubs = findCity[0].clubs
          if(findClubs.length) {
            dispatch(updateArrayClub(findClubs))
          }
        }
      }

      // ****************************** fot param CLUB ******************************

      if(gotParamClubId && paramClubId > 0 && paramClubId !== choicen.club.id) {
          citiesArr.map((city) => {
            const findClub = city.clubs.filter(club => club.id == paramClubId)
            if(findClub.length) {
              makeClub = {
                name: findClub[0].name,
                id: findClub[0].id,
                guid: findClub[0].guid,
              }
              isChange = true
            }
          })
      }

      if(gotParamClubId && paramClubId === 0 && paramClubId !== choicen.club.id) {
        makeClub = {id: 0, name: 'Не выбрано', guid: ''}
        isChange = true
        dispatch(clearArrayFilteredPers())
      }
      
      // ****************************** got param ZONE ******************************

      if(gotParamZoneId && paramZoneId > 0 && paramZoneId !== choicen.zone.id) {
        const findZone = zonesArr.filter( zone => zone.id === paramZoneId)
        if(findZone.length) {
          makeZone = {
            name: findZone[0].name,
            id: findZone[0].id,
            guid: findZone[0].guid,
          }
          isChange = true
        }
      }

      if(gotParamZoneId && paramZoneId === 0 && paramZoneId !== choicen.zone.id) {
        console.log('gotParamZoneId = 0')
        makeZone = {id: 0, name: 'Любая', guid: ''}
        isChange = true
        if(gotParamClubId && paramClubId !== 0 && paramClubId === choicen.club.id) {
          dispatch(updateArrayFilteredPers(personArr))
        }
        if(gotParamClubId && paramClubId === 0)
        dispatch(clearArrayZones())
      }

      // ****************************************************************************

      if(isChange) {
        const main = {
          city: makeCity,
          club: makeClub,
          zone: makeZone,
          qual: makeQual,
        }
        dispatch(updateChoicenMain(main))
      }
    }
  }, [citiesArr, params])

  // ********************* get Personnel and Zone in chocen Club ********************

  useEffect(() => {
    if(choicen.club.id !== 0) {
      (async function () {
        const { data: personnel } = await getPersonnelList(choicen.club.id)
        console.log('got personnel in choicen club', personnel)
        const array: PersonnelArray[] = []
        personnel.map((item) => {
          if(item.hrefMainPhoto) {
              array.push({
                id: item.id,
                firstName: item.firstName,
                surname: item.surname,
                hrefMainPhoto: item.hrefMainPhoto,
                personnelGuid: item.personnelGuid,
                emptyPhoto: false,
              })
          } else {
            array.push({
              id: item.id,
              firstName: item.firstName,
              surname: item.surname,
              hrefMainPhoto: emptyIMG,
              personnelGuid: item.personnelGuid,
              emptyPhoto: true,
            })
          }
        })
        console.log('store: personnel array in choicen club', array)
        dispatch(updateArrayPersonnel(array))

        if(paramZoneId === 0) {
          dispatch(updateArrayFilteredPers(array))
        }

        const { data: zones } = await getZonesOfClubList(choicen.club.guid)
        console.log('got zones in choicen club', zones)
        const zonesArr: choicenItem[] = [{id: 0, name: 'Любая', guid: ''}]
        zones.map((item) => {
          zonesArr.push({
            id: item.id,
            name: item.zoneType.name,
            guid: item.zoneType.guid,
          })
        })
        //console.log('store: zones array in choicen club', zonesArr)
        dispatch(updateArrayZones(zonesArr))
      })()
    }
  },[choicen.club])

  // ************************* get Personnel in chocen Zone *************************

  useEffect(() => {
    if(choicen.zone.id !== 0) {
      (async function () {
        const { data: personnelOfZone } = await getPersonnelOfZoneList(choicen.zone.id)
        //console.log('got personnel in choicen zone', personnelOfZone)
        if(personnelOfZone.length) {
          const array: PersonnelArray[] = []
          personnelOfZone.map( item => {
            let result = personArr.find(PersItem => PersItem.id === item.id) 
            if(result) {
              array.push(result)
            }
          })  
          //console.log('store: filtered personnel array in choicen club zone', array)
          dispatch(updateArrayFilteredPers(array))
        }
      })()
    }
  },[choicen.zone.id])

 
  function openCloseModule(module: string) { 
    if(openedModule === module){
      setOpenedModule('')      
    } else {
      setOpenedModule(module)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', updateSize)

    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [isMobile])

  const updateSize = () => {
    const checkMobile = checkSize()
    
    if(isMobile !== checkMobile) {
      dispatch(updateMobileDataIsMobile(checkMobile))
      //setIsMobile(mobile)
      if(!checkMobile) {
        setIsModalOpen(false)
      }
    }
  }

  const openModal = () => {
    dispatch(updateMobileDataPath(`/?cityId=${choicen.city.id}&clubId=${choicen.club.id}&zoneId=0`))
    setIsModalOpen(true)
  }

  useEffect(() => {
    const body = document.getElementsByTagName('body')
    if(body.length > 0) {
      if(isModalOpen) {
        body[0].style.overflow = 'hidden'
      } else {
        body[0].style.overflow = 'scroll'
      }
    }
  }, [isModalOpen])

  return (
    <div className='Trainers'>
      <div className='Trainers_page'>
        <div className='Trainers_page_topic'>
          <div className='Trainers_page_topic_head'>Тренеры</div>
          <div className='Trainers_page_topic_text'>В нашей команде более 20 сертифицированных профессиональных тренеров, которые помогут тебе стать лучше</div>
        </div>
        <div className='Trainers_page_container'>

          {!isMobile? 
            <FilterMain 
              openedModule={openedModule}
              openCloseModule={openCloseModule}
              isBlockCityAndClub={!!gotBlockParam}
            /> : 
            <div className='Trainers_page_container_btns'>
              {filteredPersonArr.length === personArr.length ?
                <button className='Trainers_page_container_btns_open'
                  onClick={() => openModal()}>
                  <span>Фильтры</span>
                </button>:
                <>
                  <button className='Trainers_page_container_btns_open_gray'
                    onClick={() => openModal()}>
                    <span>Фильтры ({filteredPersonArr.length})</span>
                  </button>
                  <Link to={`/?${!!gotBlockParam ? 'block=true&' : ''}cityId=${choicen.city.id}&clubId=${choicen.club.id}&zoneId=0`}>
                    <button className='Trainers_page_container_btns_clear'
                    >
                      <span>Сбросить</span>
                    </button>
                  </Link>
                </>
              }
            </div>
          }

          {isModalOpen? 
            <Modal
              openedModule={openedModule}
              openCloseModule={openCloseModule}
              setIsModalOpen={setIsModalOpen}
              isBlockCityAndClub={!!gotBlockParam}
            /> :
            false 
          }
          <div className='Trainers_page_container_main'>
            {filteredPersonArr[0]?.id !== 0 ? 
              (filteredPersonArr.map((item) => {
                return (
                  <PhotoCard 
                    key={item.id}
                    emptyPhoto={item.emptyPhoto}
                    hrefMainPhoto={item.hrefMainPhoto}
                    firstName={item.firstName}
                    surname={item.surname}
                    id={item.id}
                    isBlockCityAndClub={!!gotBlockParam}
                  />
                )
              })) : null
            }
          </div>
        </div>
      </div>
    </div>
  )
}
