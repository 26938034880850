import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import empty from '../../images/empty.png'
import { 
  getGalleryImage,
  getPersonnelProfile,
  getTrainerById,
  getHrefMainPhoto,
} from "../../api/get";
import { useAppDispatch, useAppSelector } from "../../hook"
import { useQuery } from "../../hooks/useQuery"
import { updateProfileData } from "../../store/mainInfoSlice"
import './Profile.scss'

export const Profile: React.FC = () => {

  const params = useQuery()
  const paramTrainerId = params.get('trainerId')
  const gotBlockParam = params.get('block');

  const dispath = useAppDispatch()
  
  const coach = useAppSelector(state => state.mainInfo.profile)
  const choicen = useAppSelector(state => state.choicen.main)
  const logIn = useAppSelector(state => state.mainInfo.logIn)

  const fakeTrainerGuid: string = 'e6cf6988-d738-11ec-80f4-0cc47a2a7c5d'
  const fakeHrefMainPhoto: string = '/api/photos/b653b59e-269a-46a4-889f-f1504604cabd'

  const [personPhoto, setPersonPhoto] = useState('')

  const getPath = () => {
    if(choicen.club.id !== 0) {
      return `/?${!!gotBlockParam ? 'block=true&' : ''}cityId=${choicen.city.id}&clubId=${choicen.club.id}&zoneId=${choicen.zone.id}&qualId=${choicen.qual.id}`
    } else {
      return `/?${!!gotBlockParam ? 'block=true&' : ''}clubId=${coach.storeId}`
    }
  }

  useEffect( () => {
    if(paramTrainerId && logIn) {
      (async function () {
        console.log('1) trianer id: ', paramTrainerId)
        //https://app.bitafit.com/core/api/personnel_management/client/personnel/1991
        const { data: trainerInfo } = await getTrainerById(+paramTrainerId)
        console.log('2) get trainer GuId: ', trainerInfo.personnelGuid)
        let trainerGuid
        if(trainerInfo.personnelGuid === undefined) {
          trainerGuid = fakeTrainerGuid
          console.log('3) fake Guid: ', trainerGuid)
        } else {
          trainerGuid = trainerInfo.personnelGuid 
        }       
        //console.log('getTrainerById: ', trainerInfo)

        //https://app.bitafit.com/core/api/personnel/e6cf6988-d738-11ec-80f4-0cc47a2a7c5d/photos/main
        const { data: gotHrefPhoto } = await getHrefMainPhoto(trainerGuid)
        console.log('4) got href main photo', gotHrefPhoto.href)
        let hrefMainPhoto
        if(gotHrefPhoto.href){
          hrefMainPhoto = gotHrefPhoto.href
        } else {
          console.log('5) fake href photo: ', fakeHrefMainPhoto)
          hrefMainPhoto = undefined
        }

        if(hrefMainPhoto) {
          const {data: blob} = await getGalleryImage(hrefMainPhoto)
          let reader = new window.FileReader()
          reader.readAsDataURL(blob)
          reader.onload = function () {
            let imageDataUrl = reader.result
            //console.log('getGallaryImage: ', imageDataUrl)
            if (typeof imageDataUrl === 'string') {
              setPersonPhoto(imageDataUrl)
            }
          }
        }else{
          setPersonPhoto(empty)
        }

        const { data: profile } = await getPersonnelProfile(+paramTrainerId)
        console.log('got profile', profile)  
        let qualificationsArr: string[] = []
        if(profile.qualifications.length){
          profile.qualifications.map(item => {
            qualificationsArr.push(item.name)
          })
        }
        const qualifications = qualificationsArr.join(' / ')
  
        const profileObj = {
          surname: profile.surname,
          firstName: profile.firstName, 
          patronymic: profile.patronymic,
          qualifications: qualifications,
          achievements: profile.achievements? profile.achievements.split('\n') : [],
          education: profile.education? profile.education.split('\n') : [],
          storesAddress: profile.stores[0]? profile.stores[0].address : '',
          storesName: profile.stores[0]? profile.stores[0].name : '',
          storeId: profile.stores[0]? profile.stores[0].id : 0,
          personnelGuid: trainerGuid,
        }

        dispath(updateProfileData(profileObj))
  
      })()
    }
   
  },[logIn])

  return (
    <div className="TrainerInfo">
      <div className="TrainerInfo_page">
        <Link to={getPath()}>
          <div className="TrainerInfo_page_topic">
            <div className="TrainerInfo_page_topic_circle">
              <div className="TrainerInfo_page_topic_circle_arrow"/>
            </div>
            <div className="TrainerInfo_page_topic_text">К специалистам</div>
          </div>
        </Link>
        <div className="TrainerInfo_page_container">
          <div className="TrainerInfo_page_container_side">
            <div className="TrainerInfo_page_container_side_photo">
            <img src={personPhoto} />
            </div>
          </div>
          <div className="TrainerInfo_page_container_main">
            <div className="TrainerInfo_page_container_main_name">{coach.surname}<br/>{coach.firstName}</div>
            <div className="TrainerInfo_page_container_main_info">{coach.qualifications}</div>
            <div className="TrainerInfo_page_container_main_box">
              {/* <div className="TrainerInfo_page_container_main_box_item">
                <div className="TrainerInfo_page_container_main_box_item_header">Зоны</div>
                <div className="TrainerInfo_page_container_main_box_item_text">Тренажерный зал/Мастер тренер</div>
              </div> */}

              <div className="TrainerInfo_page_container_main_box_item">
                <div className="TrainerInfo_page_container_main_box_item_header">Оказывает услуги</div>
                <div
                  className="TrainerInfo_page_container_main_box_item_text">
                  {coach.storesAddress}
                </div>
                <div
                  className="TrainerInfo_page_container_main_box_item_text">
                  {coach.storesName}
                </div>
              </div>
              {coach.achievements.length?
                <div className="TrainerInfo_page_container_main_box_item">
                  <div className="TrainerInfo_page_container_main_box_item_header">Достижения</div>
                  {coach.achievements.map((item, index) => {
                    return(
                      <div
                        className="TrainerInfo_page_container_main_box_item_text"
                        key={index}>
                        {item}
                      </div>
                    )
                  })}
                </div> : false
              }

              {coach.education.length?
                <div className="TrainerInfo_page_container_main_box_item">
                  <div className="TrainerInfo_page_container_main_box_item_header">Образование</div>
                  {coach.education.map((item, index) => {
                    return(
                      <div
                        className="TrainerInfo_page_container_main_box_item_text"
                        key={index}>
                        {item}
                      </div>
                    )
                  })}
                </div> :false
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

