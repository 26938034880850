import React from 'react'
import { Link } from 'react-router-dom';
import { useAppSelector } from '../../hook';
import { FilterMain } from '../FilterMain/FilterMain'
import './Modal.scss'

interface Modal {
  openedModule: string
  openCloseModule: Function;
  setIsModalOpen: Function;
    isBlockCityAndClub?: boolean
}

export const Modal: React.FC<Modal> = (props) => {
  const {
    openedModule = '',
    openCloseModule,
    setIsModalOpen,
      isBlockCityAndClub = false
  } = props

  const mobilePath = useAppSelector(state => state.mobileData.savedPath)

  return (
    <div className='Modal'>
      <div className='Modal_background'/>
      <div className='Modal_button-cancel'
        onClick={() => setIsModalOpen(false)}>
        <div className='Modal_button-cancel_bg1'/>
        <div className='Modal_button-cancel_bg2'/>
      </div>
      <div className='Modal_board'>
        <div className='Modal_board_header'>Фильтры</div>
        <FilterMain 
          openedModule={openedModule}
          openCloseModule={openCloseModule}
          isBlockCityAndClub={isBlockCityAndClub}
        />
      <div className='Modal_board_btns'>
        <Link to={mobilePath}>
        <button className='Modal_board_btns_cancel'
          onClick={() => setIsModalOpen(false)}>
          <span>Отмена</span>
        </button>
        </Link>
        <button className='Modal_board_btns_submit'
          onClick={() => setIsModalOpen(false)}
          ><span>Применить</span></button>
      </div>
      </div>
    </div>
  )
}