import React, {useEffect, useState}  from 'react'
import emptyIMG from '../../images/empty.png'
import './PhotoCard.scss'
import {getGalleryImage} from "../../api/get";
import { Link } from 'react-router-dom';

interface PhotoCardProps {
  emptyPhoto: boolean
  firstName: string
  surname: string
  hrefMainPhoto: string
  id: number
  isBlockCityAndClub: boolean
}

export const PhotoCard: React.FC<PhotoCardProps> = (props) => {
  const {
    emptyPhoto,
    firstName,
    surname,
    hrefMainPhoto,
    id,
    isBlockCityAndClub
  } = props

  const [personPhoto, setPersonPhoto] = useState('')

  useEffect(() => {
    (async function(){
      if(!emptyPhoto){
        const { data: blob } = await getGalleryImage(hrefMainPhoto);
        let reader = new window.FileReader()
        reader.readAsDataURL(blob)
        reader.onload = function () {
          let imageDataUrl = reader.result
          if(typeof imageDataUrl === 'string') {
            setPersonPhoto(imageDataUrl)
          }
        }
      } else {
        setPersonPhoto(emptyIMG)
      }
    })();
  }, [])

  return(
    <Link to={`/profile/?${isBlockCityAndClub ? 'block=true&' : ''}trainerId=${id}`}>
    <div 
    className={`Card_${emptyPhoto? 'item-empty': 'item'}`}
    >
      <img src={personPhoto} />
      <div className={'Card_item_text'}>
        <span>{firstName}<br/>{surname}</span>
      </div>
    </div>
    </Link>
  )
}